<template>
  <div class="notes">
    <form-repeater-basic />
  </div>
</template>

<script>
import FormRepeaterBasic from "@/views/used-forms/Form-repeater/FormRepeaterBasic.vue";

export default {
  name: "Notes",
  components: {
    FormRepeaterBasic,
  },
};
</script>
